<template>
    <div class="action-leave-chat">
        <div class="chat-exit">
            <span class="exit-message">상대방이 대화를 종료했어요</span>
            <button @click="closeChat" class="exit-button">나가기</button>
        </div>
    </div>
</template>

<script>
import chatService from '@/services/chat'

export default {
    name: 'ActionLeaveChat',
    computed: {
        chat() {
            return this.$store.getters.chat
        },
    },
    methods: {
        async closeChat() {
            const idx = await this.$modal.basic({
                body: '대화방을 나가면 대화내용이 모두 삭제되고 채팅목록에서 삭제됩니다.',
                buttons: [
                    {
                        label: '취소',
                        class: 'btn-default',
                    },
                    {
                        label: '나가기',
                        class: 'btn-primary',
                    },
                ],
            })
            if (!idx) return

            try {
                const res = await chatService.closeChat({
                    chat_id: this.chat.id,
                    user_id: this.$store.getters.me.id,
                })

                if (res) {
                    await this.$store.dispatch('loadChats', true)
                    this.$toast.success('채팅방을 나갔습니다')
                    this.$stackRouter.pop()
                }
            } catch (e) {
                console.log(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.chat-exit {
    text-align: center;
    background-color: $grey-02;
    border-radius: 16px;
    padding: 16px;

    span.exit-message {
        color: black;
        display: block;
        padding-bottom: 14px;
        font-size: 15px;
    }

    button.exit-button {
        all: unset;
        width: 100%;
        background-color: white;
        border-radius: 8px;
        padding: 6px 0;
        color: black;
        font-size: 14px;
        @include center;
    }
}
</style>
